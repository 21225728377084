import React, { useLayoutEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Box, Button, Grid, Typography } from "@mui/material";
import HueBGSVG from "../../../../assets/svg/hue_bg.svg";
import EarnIconSVG from "../../../../assets/svg/earn.svg";
import InvestIconSVG from "../../../../assets/svg/invest.svg";
import SwapIconSVG from "../../../../assets/svg/earn.svg";
import classes from "./features.module.css";

import { Link } from "react-router-dom";
import gsap from "gsap";
import Mockup from "../../../../assets/png/mockup.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import FeatureMockup from "../../../../assets/png/projects/1.png";
import FeatureDemo, {
  EarnDemo,
  InvestDemo,
  SwapDemo,
} from "../../components/FeatureDemo/FeatureDemo";

const CTA_LINK = "https://sonic.fantomstarter.io/interchain";
const LEARN_MORE_LINK =
  "https://docs.fantomstarter.io";
const FEATURES = ["Swap", "Invest", "Earn"];
const FEATURE_ICON = [SwapIconSVG, InvestIconSVG, EarnIconSVG];

const Features = () => {
  const [currentDemo, setCurrentDemo] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const rootRef = useRef();

  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: rootRef.current,
          start: "top 10%",
          end: "+=5000",
          pin: rootRef.current,
          pinSpacing: true,
          scrub: 1,
          toggleActions: "play null play null",
          immediateRender: false,
        },
      });

      if (isMobile)
        timeline.to(".primaryContent", {
          height: "0px",
          opacity: 0,
        });

      FEATURES.forEach((each, index) => {
        timeline.call(() => {
          setCurrentDemo(index);
        });

        timeline.fromTo(
          [`.${each}`, `.${each}-icon`],
          {
            opacity: 0,
            y: 20,
          },
          {
            opacity: 1,
            y: 0,
          }
        );

        timeline.call(() => {
          setCurrentDemo(index);
        });
      });

      // individual animation
      gsap.fromTo(
        ".wrapper",
        {
          opacity: 0,
          y: -100,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".wrapper",
          },
        }
      );
    }, rootRef);

    return () => ctx.revert(); // cleanup
  }, [isMobile]);

  return (
    <Box ref={rootRef}>
      <Box className="wrapper">
        <Grid container>
          <Grid
            className="primaryItem"
            sx={{
              pr: {
                md: 5,
                xs: 0,
              },
            }}
            item
            xs={12}
            md={7}
          >
            <Box className="primaryContent">
              <Typography
                className="title"
                pb={2}
                sx={{
                  fontSize: {
                    xs: 58,
                    md: 96,
                  },
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                  lineHeight: {
                    xs: "55px",
                    md: "96px",
                  },
                }}
                paragraph
                variant="titleText"
                fontWeight={700}
              >
                Elevate your crypto game.
              </Typography>

              <Typography
                pb={5}
                sx={{
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
                color="textSecondary"
                variant="h5"
              >
                Your decentralized hub for trading, earning and investing in
                blockchain startups equity, tokens or NFTs.
              </Typography>

              <Box
                sx={{
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
              >
                <Link to={{ pathname: CTA_LINK }} target={"_blank"}>
                  <Button
                    sx={{
                      mr: 2,
                      mb: {
                        md: 0,
                        xs: 2,
                      },
                    }}
                    variant="contained"
                    size="large"
                  >
                    Unlock exclusive opportunities
                  </Button>
                </Link>
                <Link to={{ pathname: LEARN_MORE_LINK }} target={"_blank"}>
                  <Button variant="outlined" size="large">
                    Learn More
                  </Button>
                </Link>
              </Box>

              <Box pt={7} />
            </Box>

            <Box
              display="flex"
              sx={{
                justifyContent: {
                  md: "left",
                  xs: "center",
                },
                alignItems: "flex-end",
              }}
            >
              {FEATURES.map((each, index) => (
                <Box mr={2} display="inline-block">
                  <img
                    className={`${each}-icon`}
                    width="64px"
                    src={FEATURE_ICON[index]}
                  />
                  <Typography
                    className={each}
                    color="textSecondary"
                    variant="h6"
                  >
                    {each}.
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box pt={10} />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            display="flex"
            position={"relative"}
            justifyContent="center"
          >
            {currentDemo === 0 ? (
              <SwapDemo />
            ) : currentDemo === 1 ? (
              <InvestDemo />
            ) : (
              <EarnDemo />
            )}

            <img
              src={HueBGSVG}
              style={{
                width: "200%",
                position: "absolute",
                top: "0px",
                zIndex: -1,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const ExchangeWidget = () => {
  return (
    <iframe
      src="https://widget.onramper.com?color=924CEE&apiKey=pk_prod_fTeblOWrTrfUL0SmajuHmQTTg5ne0qqGwa9K9JlBcR00&defaultCrypto=FTM&defaultFiatSoft=USD&defaultAmount=50&recommendedCryptoCurrencies=ETH,BTC,MATIC,FTM,USDC,USDT&supportSwap=false&darkMode=true"
      loading="lazy"
      height="620px"
      title="Onramper widget"
      frameBorder="0"
      allow="accelerometer;
                autoplay; camera; gyroscope; payment"
      style={{
        borderRadius: "20px",
        backgroundColor: "white",
        display: "block",
        margin: "auto",
      }}
    >
      <a href="https://widget.onramper.com" target="_blank">
        Buy crypto
      </a>
    </iframe>
  );
};

export default Features;
