import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import Logo from "../../assets/png/fs-logo.png";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";

// Appbar links
const CTA_LINK = "https://sonic.fantomstarter.io/interchain";
const links = [
  {
    label: "Swap",
    to: "https://sonic.fantomstarter.io/interchain",
  },
  {
    label: "Deals",
    to: "https://sonic.fantomstarter.io/projects",
  },
  {
    label: "Founders Club",
    to: "https://sonic.fantomstarter.io/sonicclub",
  },
  {
    label: "Shop",
    to: "https://sonic.fantomstarter.io/shop",
  },
  {
    label: "Earn",
    to: "https://sonic.fantomstarter.io/earn",
  },
];

const Appbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        backgroundImage: "none",
      }}
    >
      <Toolbar
        disableGutters
        style={{
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            my: 2,
          }}
        >
          <img src={Logo} height="48px" />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: {
              md: "flex",
              xs: "none",
            },
          }}
          gap={2.5}
        >
          {links.map((link) => (
            <Link to={{ pathname: link.to }} target={"_blank"}>
              <Typography
                className="link"
                color="textPrimary"
                variant="subtitle1"
              >
                {link.label}
              </Typography>
            </Link>
          ))}
        </Box>

        <Box
          sx={{
            display: {
              md: "flex",
              xs: "none",
            },
          }}
        >
          <Link to={{ pathname: CTA_LINK }} target={"_blank"}>
            <Button
              sx={{
                mr: 2,
                mb: {
                  md: 0,
                  xs: 2,
                },
              }}
              variant="contained"
              size="large"
            >
              Open the app
            </Button>
          </Link>
        </Box>

        <Box
          sx={{
            display: {
              md: "none",
              xs: "block",
            },
          }}
        >
          <IconButton onClick={() => setDrawerOpen((prev) => !prev)}>
            {drawerOpen ? <FiX /> : <FiMenu />}
          </IconButton>
        </Box>
        <MobileDrawer open={drawerOpen} />
      </Toolbar>
    </AppBar>
  );
};

export const MobileDrawer = ({ onClose, open }) => {
  const theme = useTheme();
  return (
    <Drawer
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          boxShadow: "none",
        },
      }}
      anchor={"top"}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          ...theme.mixins.toolbar,
        }}
        mt={5}
      />
      <Box px={3}>
        {links.map((link) => (
          <Box py={1.5}>
            <Link to={{ pathname: link.to }} target={"_blank"}>
              <Typography className="link" color="textPrimary" variant="h6">
                {link.label}
              </Typography>
            </Link>
          </Box>
        ))}

        <Box py={3} />
        <Link to={{ pathname: CTA_LINK }} target={"_blank"}>
          <Button
            sx={{
              mr: 2,
              mb: {
                md: 0,
                xs: 2,
              },
            }}
            variant="contained"
            size="large"
          >
            Open the app
          </Button>
        </Link>
      </Box>
    </Drawer>
  );
};

export default Appbar;
