import React from "react";
import { Box } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import useMediaQuery from "@mui/material/useMediaQuery";

const Slider = ({ childrens = [] }) => {
  const isDesktopScreen = useMediaQuery("(min-width:600px)");

  return (
    <Box>
      <Splide
        options={{
          arrows: false,
          ...(isDesktopScreen && { perPage: 3 }),
        }}
      >
        {childrens.map((children) => (
          <SplideSlide>
            <Box mx={2}>{children}</Box>
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default Slider;
