import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Logo from "../../assets/png/fs-logo.png";
import Auditor from "../../assets/svg/icons/auditor.svg";
import { Link } from "react-router-dom";

const FOOTER_LINKS = {
  products: [
    // ["Swap", "https://sonic.fantomstarter.io/interchain"],
    ["Deals", "https://sonic.fantomstarter.io/projects"],
    ["Founders Club", "https://sonic.fantomstarter.io/sonicclub"],
    ["Shop", "https://sonic.fantomstarter.io/shop"],
  ],
  company: [
    ["FAQ", "https://docs.fantomstarter.io"],
    [
      "Litepaper",
      "https://fantomstarterio.github.io/docs/English_Litepaper_v3.0.pdf",
    ],
    [
      "Audit",
      "https://fantomstarterio.github.io/docs/Audit_Report_SlowMist.pdf",
    ],
    ["Scout Program", "https://form.typeform.com/to/yQnDGQUZ"],
    ["Contact", "mailto:hello@fantomstarter.io"],
    ["Careers", "https://angel.co/company/fsmultichain"],
  ],
  social: [
    ["Twitter", "https://twitter.com/fantomstarter"],
    ["Discord", "https://discord.com/invite/nsMxVcNwy6"],
    ["Telegram", "https://t.me/fantomstarter"],
  ],
};

const PRIVACY_PAGE = "/privacy";
const TERMS_PAGE = "/terms";

const Footer = () => {
  return (
    <Box
      sx={{
        py: {
          md: 8,
          xs: 6,
        },
      }}
    >
      <Grid container>
        <Grid xs={12} md={6} item>
          <Box
            sx={{
              pb: {
                md: 0,
                xs: 3,
              },
            }}
            width="60%"
          >
            <img style={{ paddingBottom: 5 }} src={Logo} width="100px" />
            <Box
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                },
              }}
            >
              <Typography
                paragraph
                color="textSecondary"
                variant="body2"
                fontSize="12px"
                fontWeight={300}
              >
                © 2021-2023 FastSw.app. By using this service you agree to the
                Terms of Service and Privacy Policy.
              </Typography>
              <Box pb={4} display="flex" gap={2}>
                <Link to={{ pathname: PRIVACY_PAGE }} target={"_blank"}>
                  <Typography variant="body2">Privacy</Typography>
                </Link>
                <Link to={{ pathname: TERMS_PAGE }} target={"_blank"}>
                  <Typography variant="body2">Terms & Conditions</Typography>
                </Link>
              </Box>
              <Typography
                paragraph
                color="textSecondary"
                variant="body2"
                fontSize="12px"
                fontWeight={300}
              >
                AUDITED BY
              </Typography>
              <img src={Auditor} width="100px" />
            </Box>
          </Box>
        </Grid>
        <Grid xs={6} md={2} item>
          <Typography paragraph variant="body1">
            Products
          </Typography>
          {FOOTER_LINKS.products.map((text) => (
            <Link to={{ pathname: text[1] }} target={"_blank"}>
              <Typography
                gutterBottom
                color="textSecondary"
                variant="body2"
                fontSize="14px"
                fontWeight={300}
              >
                {text[0]}
              </Typography>
            </Link>
          ))}
        </Grid>
        <Grid xs={6} md={2} item>
          <Typography paragraph variant="body1">
            Company
          </Typography>
          {FOOTER_LINKS.company.map((text) => (
            <Link to={{ pathname: text[1] }} target={"_blank"}>
              <Typography
                gutterBottom
                color="textSecondary"
                variant="body2"
                fontSize="14px"
                fontWeight={300}
              >
                {text[0]}
              </Typography>
            </Link>
          ))}
        </Grid>
        <Grid xs={6} md={2} item>
          <Typography paragraph variant="body1">
            Follow us
          </Typography>
          {FOOTER_LINKS.social.map((text) => (
            <Link to={{ pathname: text[1] }} target={"_blank"}>
              <Typography
                gutterBottom
                color="textSecondary"
                variant="body2"
                fontSize="14px"
                fontWeight={300}
              >
                {text[0]}
              </Typography>
            </Link>
          ))}
        </Grid>
        <Grid
          xs={12}
          item
          sx={{
            pt: {
              md: 0,
              xs: 5,
            },
            display: {
              md: "none",
              xs: "block",
            },
          }}
        >
          <Typography
            paragraph
            color="textSecondary"
            variant="body2"
            fontSize="12px"
            fontWeight={300}
          >
            © 2021-2023 FastSw.app. By using this service you agree to the
            Terms of Service and Privacy Policy.
          </Typography>
          <Box pb={4} display="flex" gap={2}>
            <Link to={{ pathname: PRIVACY_PAGE }} target={"_blank"}>
              <Typography variant="body2">Privacy</Typography>
            </Link>
            <Link to={{ pathname: TERMS_PAGE }} target={"_blank"}>
              <Typography variant="body2">Terms & Conditions</Typography>
            </Link>
          </Box>
          <Typography
            paragraph
            color="textSecondary"
            variant="body2"
            fontSize="12px"
            fontWeight={300}
          >
            AUDITED BY
          </Typography>
          <img src={Auditor} width="100px" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
