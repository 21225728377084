import React from "react";
import "./Privacy.css";
import Appbar from "../../shared/Appbar/Appbar";
import { Box, Container, Typography } from "@mui/material";
import Footer from "../../shared/Footer/Footer";

function Privacy() {
  return (
    <Container maxWidth="lg">
      <Appbar />
      <Box
        sx={{
          marginTop: 15,
        }}
      />
      <div className="privacyContentContainer">
        <Typography variant="h1">PRIVACY POLICY</Typography>
        <p>Effective date: 11/27/2022</p>

        <div className="subContentPrivacy">
          <h2>Introduction</h2>
          <p>
            Welcome to <strong>MultiFantom LTD.</strong>
          </p>
          <p>
            MultiFantom LTD (“us”, “we”, or “our”) operates https://fs.exchange
            and FS mobile application (hereinafter referred to as “Service”).
            Our Privacy Policy governs your visit to https://fs.exchange and FS
            mobile application, and explains how we collect, safeguard and
            disclose information that results from your use of our Service. We
            use your data to provide and improve Service. By using Service, you
            agree to the collection and use of information in accordance with
            this policy. Unless otherwise defined in this Privacy Policy, the
            terms used in this Privacy Policy have the same meanings as in our
            Terms and Conditions. Our Terms and Conditions (“Terms”) govern all
            use of our Service and together with the Privacy Policy constitutes
            your agreement with us (“agreement”).
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Definitions</h2>
          <div>
            <p>
              <h3>SERVICE</h3> means the <a href="https://fs.exchange"></a>
              website and FS mobile application operated by MultiFantom LTD.
            </p>
          </div>
          <div>
            <p>
              <h3>PERSONAL DATA</h3> means data about a living individual who
              can be identified from those data (or from those and other
              information either in our possession or likely to come into our
              possession).
            </p>
          </div>
          <div>
            <p>
              <h3>USAGE DATA</h3> is data collected automatically either
              generated by the use of Service or from Service infrastructure
              itself (for example, the duration of a page visit).
            </p>
          </div>
          <div>
            <p>
              <h3>COOKIES</h3> are small files stored on your device (computer
              or mobile device).
            </p>
          </div>
          <div>
            <p>
              <h3>DATA CONTROLLER</h3> means a natural or legal person who
              (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any
              personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data.
            </p>
          </div>
          <div>
            <p>
              <h3>DATA PROCESSORS (OR SERVICE PROVIDERS)</h3> means any natural
              or legal person who processes the data on behalf of the Data
              Controller. We may use the services of various Service Providers
              in order to process your data more effectively.
            </p>
          </div>
          <div>
            <p>
              <h3>DATA SUBJECT</h3> is any living individual who is the subject
              of Personal Data.
            </p>
          </div>
          <div>
            <p>
              <h3>THE USER</h3> is the individual using our Service. The User
              corresponds to the Data Subject, who is the subject of Personal
              Data.
            </p>
          </div>
        </div>
        <div className="subContentPrivacy">
          <h2>Information Collection and Use</h2>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Types of Data Collected</h2>
          <div>
            <h3>Personal Data</h3>
            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to:
            </p>
            <p>(a) Email address</p>
            <p>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link.
            </p>
          </div>
          <div>
            <h3>Usage Data</h3>
            <p>
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through a
              mobile device (“Usage Data”).
            </p>
            <p>
              This Usage Data may include information such as your computer's
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>
            <p>
              When you access Service with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
            </p>
          </div>
          <div>
            <h3>Tracking Cookies Data</h3>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </p>
            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <ol id="marginLZero">
              <li>
                <h4>Session Cookies:</h4>{" "}
                <p>We use Session Cookies to operate our Service.</p>
              </li>
              <li>
                <h4>Preference Cookies:</h4>{" "}
                <p>
                  We use Preference Cookies to remember your preferences and
                  various settings.
                </p>
              </li>
              <li>
                <h4>Security Cookies:</h4>{" "}
                <p>We use Security Cookies for security purposes.</p>
              </li>
              <li>
                <h4>Advertising Cookies:</h4>{" "}
                <p>
                  Advertising Cookies are used to serve you with advertisements
                  that may be relevant to you and your interests.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div className="subContentPrivacy">
          <h2>Use of Data</h2>
          <p>MultiFantom LTD uses the collected data for various purposes:</p>
          <ol id="marginLZero">
            <li>
              <p>to provide and maintain our Service;</p>
            </li>
            <li>
              <p>to notify you about changes to our Service;</p>
            </li>
            <li>
              <p>
                to allow you to participate in interactive features of our
                Service when you choose to do so;
              </p>
            </li>
            <li>
              <p>to provide customer support;</p>
            </li>
            <li>
              <p>
                to gather analysis or valuable information so that we can
                improve our Service;
              </p>
            </li>
            <li>
              <p>to monitor the usage of our Service;</p>
            </li>
            <li>
              <p>to detect, prevent and address technical issues;</p>
            </li>
            <li>
              <p>to fulfill any other purpose for which you provide it;</p>
            </li>
            <li>
              <p>
                to carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection;
              </p>
            </li>
            <li>
              <p>
                to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </p>
            </li>
            <li>
              <p>
                to provide you with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless you have opted not to receive such information;
              </p>
            </li>
            <li>
              <p>
                in any other way we may describe when you provide the
                information;
              </p>
            </li>
            <li>
              <p>for any other purpose with your consent.</p>
            </li>
          </ol>
        </div>
        <div className="subContentPrivacy">
          <h2>Retention of Data</h2>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Transfer of Data</h2>
          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            MultiFantom LTD will take all the steps reasonably necessary to
            ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Disclosure of Data</h2>
          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <ol id="marginLZero">
            <li>
              <div>
                <h3>Disclosure for Law Enforcement.</h3>
                <p>
                  Under certain circumstances, we may be required to disclose
                  your Personal Data if required to do so by law or in response
                  to valid requests by public authorities.
                </p>
              </div>
            </li>
            <li>
              <div>
                <h3>Business Transaction.</h3>
                <p>
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </p>
              </div>
            </li>
            <li>
              <div>
                <h3>Other cases. We may disclose your information also:</h3>
                <ol id="marginLZero">
                  <li>
                    <p>to our subsidiaries and affiliates;</p>
                  </li>
                  <li>
                    <p>
                      to contractors, service providers, and other third parties
                      we use to support our business;
                    </p>
                  </li>
                  <li>
                    <p>to fulfill the purpose for which you provide it;</p>
                  </li>
                  <li>
                    <p>
                      for the purpose of including your company’s logo on our
                      website;
                    </p>
                  </li>
                  <li>
                    <p>
                      for any other purpose disclosed by us when you provide the
                      information;
                    </p>
                  </li>
                  <li>
                    <p>with your consent in any other cases;</p>
                  </li>
                  <li>
                    <p>
                      if we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of the Company,
                      our customers, or others.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </div>
        <div className="subContentPrivacy">
          <h2>Security of Data</h2>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>
            Your Data Protection Rights Under General Data Protection Regulation
            (GDPR)
          </h2>
          <p>
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR. – See more at
            <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
              https://eur-lex.europa.eu/eli/reg/2016/679/oj
            </a>
          </p>
          <p>
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
          </p>
          <p>
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please email us at
            hi@fs.exchange.
          </p>
          <p>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ol id="marginLZero">
            <li>
              <p>
                the right to access, update or to delete the information we have
                on you;
              </p>
            </li>
            <li>
              <p>
                the right of rectification. You have the right to have your
                information rectified if that information is inaccurate or
                incomplete;
              </p>
            </li>
            <li>
              <p>
                the right to object. You have the right to object to our
                processing of your Personal Data;
              </p>
            </li>
            <li>
              <p>
                the right of restriction. You have the right to request that we
                restrict the processing of your personal information;
              </p>
            </li>
            <li>
              <p>
                the right to data portability. You have the right to be provided
                with a copy of your Personal Data in a structured,
                machine-readable and commonly used format;
              </p>
            </li>
            <li>
              <p>
                the right to withdraw consent. You also have the right to
                withdraw your consent at any time where we rely on your consent
                to process your personal information;
              </p>
            </li>
          </ol>
          <p>
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>
          <p>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>
            Your Data Protection Rights under the California Privacy Protection
            Act (CalOPPA)
          </h2>
          <p>
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require a person or
            company in the United States (and conceivable the world) that
            operates websites collecting personally identifiable information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals with whom it is being shared, and to comply with
            this policy. – See more at:
            <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
              https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
            </a>
          </p>
          <p>According to CalOPPA we agree to the following:</p>
          <ol id="marginLZero">
            <li>
              <p>users can visit our site anonymously;</p>
            </li>
            <li>
              <p>
                our Privacy Policy link includes the word “Privacy”, and can
                easily be found on the page specified above on the home page of
                our website;
              </p>
            </li>
            <li>
              <p>
                users will be notified of any privacy policy changes on our
                Privacy Policy Page;
              </p>
            </li>
            <li>
              <p>
                users are able to change their personal information by emailing
                us at hi@fs.exchange.
              </p>
            </li>
          </ol>
          <p>Our Policy on “Do Not Track” Signals:</p>
          <p>
            We honor Do Not Track signals and do not track, plant cookies, or
            use advertising when a Do Not Track browser mechanism is in place.
            Do Not Track is a preference you can set in your web browser to
            inform websites that you do not want to be tracked.
          </p>
          <p>
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>
            Your Data Protection Rights under the California Consumer Privacy
            Act (CCPA)
          </h2>
          <p>
            If you are a California resident, you are entitled to learn what
            data we collect about you, ask to delete your data and not to sell
            (share) it. To exercise your data protection rights, you can make
            certain requests and ask us:
          </p>
          <ol id="marginLZero">
            <li>
              <h4>What personal information we have about you.</h4>
              <p>If you make this request, we will return to you:</p>
              <ol id="marginLZero">
                <li>
                  <p>
                    The categories of personal information we have collected
                    about you.
                  </p>
                </li>
                <li>
                  <p>
                    The categories of sources from which we collect your
                    personal information.
                  </p>
                </li>
                <li>
                  <p>
                    The business or commercial purpose for collecting or selling
                    your personal information.
                  </p>
                </li>
                <li>
                  <p>
                    The categories of third parties with whom we share personal
                    information.
                  </p>
                </li>
                <li>
                  <p>
                    The specific pieces of personal information we have
                    collected about you.
                  </p>
                </li>
                <li>
                  <p>
                    A list of categories of personal information that we have
                    sold, along with the category of any other company we sold
                    it to. If we have not sold your personal information, we
                    will inform you of that fact.
                  </p>
                </li>
                <li>
                  <p>
                    A list of categories of personal information that we have
                    disclosed for a business purpose, along with the category of
                    any other company we shared it with.
                  </p>
                </li>
              </ol>
              <p>
                Please note, you are entitled to ask us to provide you with this
                information up to two times in a rolling twelve-month period.
                When you make this request, the information provided may be
                limited to the personal information we collected about you in
                the previous 12 months.
              </p>
            </li>
            <li>
              <h4>To delete your personal information.</h4>
              <p>
                If you make this request, we will delete the personal
                information we hold about you as of the date of your request
                from our records and direct any service providers to do the
                same. In some cases, deletion may be accomplished through
                de-identification of the information. If you choose to delete
                your personal information, you may not be able to use certain
                functions that require your personal information to operate.
              </p>
            </li>
            <li>
              <h4>To stop selling your personal information.</h4>
              <p>
                We don't sell or rent your personal information to any third
                parties for any purpose. You are the only owner of your Personal
                Data and can request disclosure or deletion at any time.
              </p>
              <p>
                Please note, if you ask us to delete or stop selling your data,
                it may impact your experience with us, and you may not be able
                to participate in certain programs or membership services which
                require the usage of your personal information to function. But
                in no circumstances, we will discriminate against you for
                exercising your rights.
              </p>
              <p>
                To exercise your California data protection rights described
                above, please send your request(s) by one of the following
                means:
              </p>
              <p>By email: hi@fs.exchange</p>
            </li>
          </ol>
          <p>
            Your data protection rights, described above, are covered by the
            CCPA, short for the California Consumer Privacy Act. To find out
            more, visit the official California Legislative Information website.
            The CCPA took effect on 01/01/2020.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Service Providers</h2>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service (“<b>Service Providers</b>”), provide Service on our
            behalf, perform Service-related services or assist us in analysing
            how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
        </div>
        <div className="subContentPrivacy">
          div
          <h2>Analytics</h2>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <div>
            <h3>Google Analytics</h3>
            <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network.
            </p>
            <p>
              For more information on the privacy practices of Google, please
              visit the Google Privacy Terms web page:{" "}
              <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
            </p>
            <p>
              We also encourage you to review the Google's policy for
              safeguarding your data:{" "}
              <a href="https://support.google.com/analytics/answer/6004245.">
                https://support.google.com/analytics/answer/6004245.
              </a>
            </p>
          </div>
        </div>
        <div className="subContentPrivacy">
          <h2>CI/CD tools</h2>
          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>
          <h3>GitHub</h3>
          <p>GitHub is provided by GitHub, Inc.</p>
          <p>
            GitHub is a development platform to host and review code, manage
            projects, and build software.
          </p>
          <p>
            For more information on what data GitHub collects for what purpose
            and how the protection of the data is ensured, please visit GitHub
            Privacy Policy page:
            <a href="https://help.github.com/en/articles/github-privacy-statement.">
              https://help.github.com/en/articles/github-privacy-statement.
            </a>
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Behavioral Remarketing</h2>
          <p>
            MultiFantom LTD uses remarketing services to advertise on third
            party websites to you after you visited our Service. We and our
            third-party vendors use cookies to inform, optimise and serve ads
            based on your past visits to our Service.
          </p>
          <h3>Google Ads (AdWords)</h3>
          <p>
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </p>
          <p>
            You can opt-out of Google Analytics for Display Advertising and
            customize the Google Display Network ads by visiting the Google Ads
            Settings page:
            <a href="http://www.google.com/settings/ads">
              http://www.google.com/settings/ads
            </a>
          </p>
          <p>
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on –
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
            – for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics.
          </p>
          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:
            <a href="https://policies.google.com/privacy?hl=en">
              https://policies.google.com/privacy?hl=en
            </a>
          </p>
          <h3>Twitter</h3>
          <p>Twitter remarketing service is provided by Twitter Inc.</p>
          <p>
            You can opt-out from Twitter's interest-based ads by following their
            instructions:{" "}
            <a href="https://support.twitter.com/articles/20170405">
              https://support.twitter.com/articles/20170405
            </a>
          </p>
          <p>
            You can learn more about the privacy practices and policies of
            Twitter by visiting their Privacy Policy page:
            <a href="https://twitter.com/privacy">
              https://twitter.com/privacy
            </a>
          </p>
          <h3>Facebook</h3>
          <p>Facebook remarketing service is provided by Facebook Inc.</p>
          <p>
            You can learn more about interest-based advertising from Facebook by
            visiting this page:{" "}
            <a href="ttps://www.facebook.com/help/164968693837950">
              ttps://www.facebook.com/help/164968693837950
            </a>
          </p>
          <p>
            To opt-out from Facebook's interest-based ads, follow these
            instructions from Facebook:
            <a href="To opt-out from Facebook's interest-based ads, follow these instructions from Facebook: https://www.facebook.com/help/568137493302217">
              To opt-out from Facebook's interest-based ads, follow these
              instructions from Facebook:
              https://www.facebook.com/help/568137493302217
            </a>
          </p>
          <p>
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
            , the Digital Advertising Alliance of Canada in Canada
            <a href="http://youradchoices.ca/">http://youradchoices.ca/</a> or
            the European Interactive Digital Advertising Alliance in Europe
            <a href="http://youradchoices.ca/">http://youradchoices.ca/</a>, or
            opt-out using your mobile device settings.
          </p>
          <p>
            For more information on the privacy practices of Facebook, please
            visit Facebook's Data Policy:
            <a href="https://www.facebook.com/privacy/explanation">
              https://www.facebook.com/privacy/explanation
            </a>
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Payments</h2>
          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors). We will not store or collect your payment card
            details. That information is provided directly to our third-party
            payment processors whose use of your personal information is
            governed by their Privacy Policy. These payment processors adhere to
            the standards set by PCI-DSS as managed by the PCI Security
            Standards Council, which is a joint effort of brands like Visa,
            Mastercard, American Express and Discover. PCI-DSS requirements help
            ensure the secure handling of payment information.
          </p>
          <p>The payment processors we work with are:</p>
          <h3>PayPal or Braintree:</h3>
          <p>
            Their Privacy Policy can be viewed at
            <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
              https://www.paypal.com/webapps/mpp/ua/privacy-full
            </a>
          </p>
          <h3>Apple Store In-App Payments:</h3>
          <p>
            Their Privacy Policy can be viewed at:
            <a href="https://www.apple.com/legal/privacy/en-ww/">
              https://www.apple.com/legal/privacy/en-ww/
            </a>
            <a href="https://support.apple.com/en-us/HT203027">
              https://support.apple.com/en-us/HT203027
            </a>
          </p>
          <h3>Google Play In-App Payments:</h3>
          <p>
            Their Privacy Policy can be viewed at:
            <a href="https://policies.google.com/privacy?hl=en&gl=us /">
              https://policies.google.com/privacy?hl=en&gl=us /
            </a>
            <a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en">
              https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en
            </a>
          </p>
          <h3>Stripe:</h3>
          <p>
            Their Privacy Policy can be viewed at:
            <a href="https://stripe.com/us/privacy">
              https://stripe.com/us/privacy
            </a>
          </p>
          <h3>2Checkout:</h3>
          <p>
            Their Privacy Policy can be viewed at:
            <a href=" https://www.2checkout.com/legal/privacy/">
              https://www.2checkout.com/legal/privacy/
            </a>
          </p>
          <h3>Adyen:</h3>
          <p>
            Their Terms and conditions can be viewed at
            <a href="https://www.adyen.com/legal/terms-and-conditions.">
              https://www.adyen.com/legal/terms-and-conditions.
            </a>
          </p>
          <h3>Klarna:</h3>
          <p>
            Their Services Terms, Privacy Policy and Buyer Protection Policy can
            be viewed at{" "}
            <a href="https://www.klarna.com/us/legal/.">
              https://www.klarna.com/us/legal/.
            </a>
          </p>
          <p>
            cTheir End User Privacy Policy, Cookie Policy and Privacy Statement
            can be viewed at{" "}
            <a href="cTheir End User Privacy Policy, Cookie Policy and Privacy Statement can be viewed at https://plaid.com/legal/.">
              cTheir End User Privacy Policy, Cookie Policy and Privacy
              Statement can be viewed at https://plaid.com/legal/.
            </a>
          </p>
          <h3>Square:</h3>
          <p>
            Their Privacy Policy can be viewed at:
            <a href="https://squareup.com/us/en/legal/general/privacy">
              https://squareup.com/us/en/legal/general/privacy
            </a>
          </p>
          <h3>WeChat:</h3>
          <p>
            Their Privacy Policy can be viewed at:
            <a href="https://www.wechat.com/en/privacy_policy.html">
              https://www.wechat.com/en/privacy_policy.html
            </a>
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Links to Other Sites</h2>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Children's Privacy</h2>
          <p>
            Our Services are not intended for use by children under the age of
            13 (“Children”).
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            Children under 13. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
        <div className="subContentPrivacy">
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <p>By email: hi@fs.exchange.</p>
        </div>
      </div>
      <Footer />
    </Container>
  );
}

export default Privacy;
