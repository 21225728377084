import React, { useLayoutEffect, useRef } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Link } from "react-router-dom";
import Appbar from "../../shared/Appbar/Appbar";
import Wrapper from "../../shared/Wrapper/Wrapper";
import SwapSVG from "../../assets/svg/icons/swap.svg";
import MoneySVG from "../../assets/svg/icons/money.png";
import ProfileSVG from "../../assets/svg/icons/profile.png";

import EarnPNG from "../../assets/png/earn.png";
import FSmartClubPNG from "../../assets/png/seed.svg";
import SwapPNG from "../../assets/png/swap_content.svg";
import HueBGSVG from "../../assets/svg/hue_bg.svg";

// Chains
import PolygonSVG from "../../assets/svg/chains/Polygon_logo-white 80_.svg";
import BSCSVG from "../../assets/svg/chains/BSC_logo-white 80_.svg";
import EthereumSVG from "../../assets/svg/chains/Ethereum_logo-white 80_.svg";
import FantomSVG from "../../assets/svg/chains/Fantom_logo-white 80_.svg";

// Sponsors
import A from "../../assets/png/sponsors/1.png";
import B from "../../assets/png/sponsors/2.png";
import C from "../../assets/png/sponsors/3.png";
import D from "../../assets/png/sponsors/4.png";
import E from "../../assets/png/sponsors/5.png";
import F from "../../assets/png/sponsors/6.png";

// Projects
import P1 from "../../assets/png/projects/1.png";
import P2 from "../../assets/png/projects/2.png";
import P3 from "../../assets/png/projects/3.png";

import Footer from "../../shared/Footer/Footer";
import { FiArrowUpRight, FiLink } from "react-icons/fi";
import Slider from "./components/Slider/Slider";
import useMediaQuery from "@mui/material/useMediaQuery";
import gsap from "gsap";
import Features from "./sections/Features/Features";
import Hero from "./sections/Hero/Hero";

const Sponsors = [A, B, C, D, E, F];

const Chains = [FantomSVG, BSCSVG, PolygonSVG, EthereumSVG];

// Links, Text and Image
const CTA_LINK = "https://sonic.fantomstarter.io/interchain";
const LEARN_MORE_LINK = "https://docs.fantomstarter.io";
const SWAP_NOW_LINK = "https://sonic.fantomstarter.io/interchain";
const EARN_TODAY_LINK = "https://sonic.fantomstarter.io/earn";
const SEE_BENEFITS_LINK = "https://sonic.fantomstarter.io/sonicclub";
const HOW_TO_PARTICIPATE_LINK =
  "https://fantomstarter.medium.com/fantomstarter-best-practices-52b9dba3c5f4";
const VIEW_MORE_LINK = "https://docs.fantomstarter.io";

const Home = () => {
  return (
    <Container maxWidth="lg">
      <Appbar />

      <Box
        sx={{
          marginTop: 15,
        }}
        className="padTop"
      />
      <Features />

      <Box
        sx={{
          display: {
            md: "flex",
            xs: "none",
            alignItems: "center",
            width: "100%",
            gap: "80px",
          },
        }}
        width="70%"
        container
      >
        {Chains.map((chain) => (
          <img width="100%" src={chain} />
        ))}
      </Box>
      <Box
        sx={{
          py: {
            md: 10,
            xs: 0,
          },
          my: {
            xs: -40,
            md: 0,
          },
        }}
      />

      <Box>
        <Box
          sx={{
            width: {
              md: "49%",
              xs: "100%",
            },
          }}
          margin="auto"
        >
          <Typography paragraph variant="subTitle" textAlign="center">
            Get the most out of your crypto.
          </Typography>

          <Typography
            pb={10}
            variant="body1"
            color="textSecondary"
            textAlign="center"
          >
            Buy, Sell and Swap your tokens, invest in amazing tokens and NFT
            projects, and earn the best yields from one place.<br></br>
            Decentralized and self-custodial.
          </Typography>
        </Box>

        <Card
          title={"Buy, Sell, Swap"}
          image={SwapSVG}
          desc={
            "On-ramp and Off-ramp Crypto or Swap Now Across 30+ Blockchains at the Best Rates"
          }
          innerImage={SwapPNG}
          cta={"Get the Best Rates"}
          link={SWAP_NOW_LINK}
        />

        <Box py={4} />

        <Card
          title={"Earn with your crypto"}
          image={MoneySVG}
          desc={"Revolutionize Your Crypto Investments with FS"}
          floatingImage={EarnPNG}
          reverse
          cta={"Start Earning Today"}
          link={EARN_TODAY_LINK}
        />

        <Box py={4} />

        <Card
          title={"Join FSmart Club and Earn"}
          image={ProfileSVG}
          desc={
            "Unlock the ultimate crypto investing experience today! Start earning with FS Liquid Staking"
          }
          innerImage={FSmartClubPNG}
          cta={"See Benefits"}
          link={SEE_BENEFITS_LINK}
        />

        <Box py={8} />

        <Box
          sx={{
            px: {
              xs: 0,
              md: 10,
            },
          }}
        >
          <Typography pb={5} variant="subTitle" textAlign="center">
            Join the revolution
          </Typography>
          <Box
            sx={{
              flexDirection: {
                md: "row",
                xs: "column",
              },
              gap: {
                md: 0,
                xs: 6,
              },
            }}
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Stat label="15K+" desc="Total Wallets" />
            <Stat label="250K+" desc="Total Swaps" />
            <Stat label="$2.1M+" desc="Total Volume" />
          </Box>
        </Box>
      </Box>

      <Box py={8} />
      <Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="subTitle" pb={3} textAlign="center">
            Seed opportunities
          </Typography>
          <Typography variant="body2" textAlign="center">
            Some of our amazing and exclusive Seed sales.
          </Typography>
          <Box display="flex" gap={2} justifyContent="center">
            <Link to={{ pathname: HOW_TO_PARTICIPATE_LINK }} target={"_blank"}>
              <Button
                endIcon={<FiArrowUpRight />}
                color="primary"
                variant="link"
                size="small"
              >
                How to participate
              </Button>
            </Link>
            <Link to={{ pathname: VIEW_MORE_LINK }} target={"_blank"}>
              <Button
                endIcon={<FiArrowUpRight />}
                color="primary"
                variant="link"
                size="small"
              >
                View more
              </Button>
            </Link>
          </Box>
        </Box>

        <Box
          position="relative"
          display="flex"
          gap={3}
          py={5}
          justifyContent="center"
        >
          <Slider
            childrens={[
              <ProjectSlide image={P2} />,
              <ProjectSlide image={P3} />,
              <ProjectSlide image={P1} />,
            ]}
          />

          <img
            src={HueBGSVG}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: -1,
            }}
            width="150%"
          />
        </Box>
      </Box>

      <Box py={4} />

      <Box>
        <Typography pb={10} variant="subTitle" textAlign="center">
          Backed by some amazing folks.
        </Typography>
        <Grid
          container
          spacing={{
            xs: 4,
            md: 5,
          }}
          sx={{
            width: {
              xs: "auto",
            },
          }}
          margin="auto"
        >
          {Sponsors.map((sponsor) => (
            <Grid item xs={6} md={4}>
              <img
                style={{
                  margin: "auto",
                  display: "inherit",
                }}
                width="100px"
                src={sponsor}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box py={5} />

      <Footer />
    </Container>
  );
};

const Stat = ({ label, desc }) => {
  return (
    <Box>
      <Typography
        variant="titleText"
        letterSpacing={"-1px"}
        fontSize="48px"
        fontWeight={700}
        lineHeight="50px"
      >
        {label}
      </Typography>
      <Typography variant="body1">{desc}</Typography>
    </Box>
  );
};

const Card = ({
  image,
  title,
  desc,
  innerImage,
  floatingImage,
  reverse,
  cta = "Swap now",
  link,
}) => {
  return (
    <Wrapper
      height="auto"
      overflow="hidden"
      py={6}
      sx={{
        width: {
          md: "80%",
          xs: "100%",
        },
        px: {
          md: 8,
          xs: 4,
        },
      }}
      showHue={false}
    >
      <Grid
        spacing={5}
        container
        alignItems="center"
        flexDirection={reverse ? "row-reverse" : "row"}
      >
        <Grid
          item
          display="flex"
          flexDirection="column"
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <img src={image} width="48px" />
            <Typography variant={"h5"}>{title}</Typography>

            <Typography
              paragraph
              fontWeight={200}
              color="textSecondary"
              variant={"body2"}
            >
              {desc}
            </Typography>

            <Link to={{ pathname: link }} target={"_blank"}>
              <Button variant="contained">{cta}</Button>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Wrapper
            hidden
            height={reverse ? "200px" : "auto"}
            sx={{
              width: {
                md: "65%",
                xs: "100%",
              },
            }}
          >
            <img
              src={innerImage}
              style={{ margin: "auto", display: "block" }}
              width="100%"
            />
          </Wrapper>
          <img
            src={floatingImage}
            width="300px"
            style={{
              position: "absolute",
              bottom: 0,
              margin: "auto",
              display: "block",
              marginLeft: "30px",
            }}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const ProjectSlide = ({ image }) => {
  const isDesktopScreen = useMediaQuery("(min-width:600px)");

  return (
    <Box>
      <img width={isDesktopScreen ? "300px" : "100%"} src={image} />
    </Box>
  );
};

export default Home;
