import logo from "./logo.svg";
import "./App.css";
import { Box, CssBaseline } from "@mui/material";
import Home from "./pages/Home/Home";
import { Switch, Route } from "react-router-dom";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";

function App() {
  return (
    <Box
      style={{
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
      </Switch>
    </Box>
  );
}

export default App;
