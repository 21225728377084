import React from "react";
import Appbar from "../../shared/Appbar/Appbar";
import Footer from "../../shared/Footer/Footer";
import { Container, Typography } from "@mui/material";
import "./Terms.css";

function Terms() {
  return (
    <Container maxWidth="lg">
      <Appbar />
      <div className="termsContainer">
        <div className="contentContainer">
          <h1>Terms and conditions</h1>
          <div className="subContent">
            <Typography variant="h5">DISCLAIMER</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              PLEASE READ THE ENTIRETY OF THIS "DISCLAIMER" SECTION CAREFULLY.
              NOTHING HEREIN CONSTITUTES LEGAL, FINANCIAL, BUSINESS OR TAX
              ADVICE AND YOU SHOULD CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR
              OTHER PROFESSIONAL ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY IN
              CONNECTION HEREWITH. NEITHER MULTIFANTOM LTD (THE{" "}
              <span>COMPANY</span>), ANY OF THE PROJECT TEAM MEMBERS (THE{" "}
              <span>FANTOMSTARTER TEAM</span>) WHO HAVE WORKED ON FANTOMSTARTER
              (AS DEFINED HEREIN) OR PROJECT TO DEVELOP FANTOMSTARTER IN ANY WAY
              WHATSOEVER, ANY DISTRIBUTOR/VENDOR OF $FS TOKENS (THE{" "}
              <span>DISTRIBUTOR</span>), NOR ANY SERVICE PROVIDER SHALL BE
              LIABLE FOR ANY KIND OF DIRECT OR INDIRECT DAMAGE OR LOSS
              WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH ACCESSING THIS
              LITEPAPER, THE WEBSITE AT{" "}
              <a href="HTTPS://WWW.FANTOMSTARTER.IO/">
                HTTPS://WWW.FANTOMSTARTER.IO/
              </a>{" "}
              (THE <span>WEBSITE</span>) OR ANY OTHER WEBSITES OR MATERIALS
              PUBLISHED BY THE COMPANY.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">Project purpose</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              You agree that you are acquiring $FS to participate in
              FantomStarter and to obtain services on the ecosystem thereon. The
              Company, the Distributor and their respective affiliates would
              develop and contribute to the underlying source code for
              FantomStarter. The Company is acting solely as an arms’ length
              third party in relation to the $FS distribution, and not in the
              capacity as a financial advisor or fiduciary of any person with
              regard to the distribution of $FS.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">Nature of the Litepaper</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              The Litepaper and the Website are intended for general
              informational purposes only and do not constitute a prospectus, an
              document, an offer of securities, a solicitation for investment,
              or any offer to sell any product, item, or asset (whether digital
              or otherwise). The information herein may not be exhaustive and
              does not imply any element of a contractual relationship. There is
              no assurance as to the accuracy or completeness of such
              information and no representation, warranty or undertaking is or
              purported to be provided as to the accuracy or completeness of
              such information. Where the Litepaper or the Website includes
              information that has been obtained from third party sources, the
              Company, the Distributor, their respective affiliates and/or the
              FantomStarter team have not independently verified the accuracy or
              completeness of such information. Further, you acknowledge that
              circumstances may change and that the Litepaper or the Website may
              become outdated as a result; and neither the Company nor the
              Distributor is under any obligation to update or correct this
              document in connection therewith.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">Token Documentation</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              Nothing in the Litepaper or the Website constitutes any offer by
              the Company, the Distributor, or the FantomStarter team to sell
              any $FS (as defined herein) nor shall it or any part of it nor the
              fact of its presentation form the basis of, or be relied upon in
              connection with, any contract or investment decision. Nothing
              contained in the Litepaper or the Website is or may be relied upon
              as a promise, representation or undertaking as to the future
              performance of FantomStarter. The agreement between the
              Distributor (or any third party) and you, in relation to any
              distribution or transfer of $FS, is to be governed only by the
              separate terms and conditions of such agreement.
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              The information set out in the Litepaper and the Website is for
              community discussion only and is not legally binding. No person is
              bound to enter into any contract or binding legal commitment in
              relation to the acquisition of $FS, and no digital asset or other
              form of payment is to be accepted on the basis of the Litepaper or
              the Website. The agreement for distribution of $FS and/or
              continued holding of $FS shall be governed by a separate set of
              Terms and Conditions or Token Distribution Agreement (as the case
              may be) setting out the terms of such distribution and/or
              continued holding of $FS (the Terms and Conditions), which shall
              be separately provided to you or made available on the Website.
              The Terms and Conditions must be read together with the Litepaper.
              In the event of any inconsistencies between the Terms and
              Conditions and the Litepaper or the Website, the Terms and
              Conditions shall prevail.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">
              Deemed Representations and Warranties
            </Typography>
            <div>
              <Typography variant="body2" paragraph color="textSecondary">
                By accessing the Litepaper or the Website (or any part thereof),
                you shall be deemed to represent and warrant to the Company, the
                Distributor, their respective affiliates, and the FantomStarter
                team as follows:
              </Typography>
              <ol>
                <li>
                  in any decision to acquire any $FS, you have shall not rely on
                  any statement set out in the Litepaper or the Website;
                </li>
                <li>
                  you will and shall at your own expense ensure compliance with
                  all laws, regulatory requirements and restrictions applicable
                  to you (as the case may be);
                </li>
                <li>
                  you acknowledge, understand and agree that $FS may have no
                  value, there is no guarantee or representation of value or
                  liquidity for $FS, and $FS is not an investment product nor is
                  it intended for any speculative investment whatsoever;
                </li>
                <li>
                  none of the Company, the Distributor, their respective
                  affiliates, and/or the FantomStarter team members shall be
                  responsible for or liable for the value of $FS, the
                  transferability and/or liquidity of $FS and/or the
                  availability of any market for $FS through third parties or
                  otherwise; and
                </li>
                <li>
                  you acknowledge, understand and agree that you are not
                  eligible to participate in the distribution of $FS if you are
                  a citizen, national, resident (tax or otherwise), domiciliary
                  and/or green card holder of a geographic area or country (i)
                  where it is likely that the distribution of $FS would be
                  construed as the sale of a security (howsoever named),
                  financial service or investment product and/or (ii) where
                  participation in token distributions is prohibited by
                  applicable law, decree, regulation, treaty, or administrative
                  act (including without limitation the United States of America
                  and the People's Republic of China); and to this effect you
                  agree to provide all such identity verification document when
                  requested in order for the relevant checks to be carried out.
                </li>
              </ol>
              <Typography variant="body2" paragraph color="textSecondary">
                The Company, the Distributor and the FantomStarter team do not
                and do not purport to make, and hereby disclaims, all
                representations, warranties or undertaking to any entity or
                person (including without limitation warranties as to the
                accuracy, completeness, timeliness, or reliability of the
                contents of the Litepaper or the Website, or any other materials
                published by the Company or the Distributor). To the maximum
                extent permitted by law, the Company, the Distributor, their
                respective affiliates and service providers shall not be liable
                for any indirect, special, incidental, consequential or other
                losses of any kind, in tort, contract or otherwise (including,
                without limitation, any liability arising from default or
                negligence on the part of any of them, or any loss of revenue,
                income or profits, and loss of use or data) arising from the use
                of the Litepaper or the Website, or any other materials
                published, or its contents (including without limitation any
                errors or omissions) or otherwise arising in connection with the
                same. Prospective acquirors of $FS should carefully consider and
                evaluate all risks and uncertainties (including financial and
                legal risks and uncertainties) associated with the distribution
                of $FS, the Company, the Distributor and the FantomStarter team.
              </Typography>
            </div>
          </div>
          <div className="subContent">
            <Typography variant="h5">$FS Token</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              The native cryptographically-secured fungible protocol token of
              FantomStarter (ticker symbol $FS) is a transferable representation
              of attributed governance and utility functions specified in the
              protocol/code of FantomStarter, and which is designed to be used
              solely as an interoperable utility token thereon.
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              $FS provides the economic incentives which will be distributed to
              encourage users to exert efforts towards contribution and
              participation in the ecosystem on FantomStarter, thereby creating
              a mutually beneficial system where every participant is fairly
              compensated for its efforts. $FS is an integral and indispensable
              part of FantomStarter, because without $FS, there would be no
              incentive for users to expend resources to participate in
              activities or provide services for the benefit of the entire
              ecosystem on FantomStarter. Given that additional $FS will be
              awarded to a user based only on its actual usage, activity and
              efforts made on FantomStarter and/or proportionate to the
              frequency and volume of transactions, users of FantomStarter
              and/or holders of $FS which did not actively participate will not
              receive any $FS incentives.
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              In order to promote decentralised community governance for the
              network, $FS would allow holders to create and vote on on-chain
              governance proposals to determine future features and/or
              parameters of FantomStarter (e.g. projects overlooked by the FS
              team but well-liked by the community can be picked up through
              governance as Token & NFT holders use their voting power to
              champion the projects they believe in). For the avoidance of
              doubt, the right to vote is restricted solely to voting on
              features of FantomStarter; it does not entitle $FS holders to vote
              on the operation and management of the Company, its affiliates, or
              their assets or the disposition of such assets to token holders,
              or select the board of directors of these entities, or determine
              the development direction of these entities, does not constitute
              any equity interest in any of these entities or any collective
              investment scheme; the arrangement is not intended to be any form
              of joint venture or partnership.
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              $FS does not in any way represent any shareholding, participation,
              right, title, or interest in the Company, the Distributor, their
              respective affiliates, or any other company, enterprise or
              undertaking, nor will $FS entitle token holders to any promise of
              fees, dividends, revenue, profits or investment returns, and are
              not intended to constitute securities in Singapore or any relevant
              jurisdiction. $FS may only be utilised on FantomStarter, and
              ownership of $FS carries no rights, express or implied, other than
              the right to use $FS as a means to enable usage of and interaction
              within FantomStarter. In particular, it is highlighted that $FS:
            </Typography>
            <ol>
              <li>
                does not have any tangible or physical manifestation, and does
                not have any intrinsic value (nor does any person make any
                representation or give any commitment as to its value);
              </li>
              <li>
                is non-refundable and cannot be exchanged for cash (or its
                equivalent value in any other digital asset) or any payment
                obligation by the Company, the Distributor or any of their
                respective affiliates;
              </li>
              <li>
                does not represent or confer on the token holder any right of
                any form with respect to the Company, the Distributor (or any of
                their respective affiliates), or its revenues or assets,
                including without limitation any right to receive future
                dividends, revenue, shares, ownership right or stake, share or
                security, any voting, distribution, redemption, liquidation,
                proprietary (including all forms of intellectual property or
                licence rights), right to receive accounts, financial statements
                or other financial data, the right to requisition or participate
                in shareholder meetings, the right to nominate a director, or
                other financial or legal rights or equivalent rights, or
                intellectual property rights or any other form of participation
                in or relating to FantomStarter, the Company, the Distributor
                and/or their service providers;
              </li>
              <li>
                is not intended to represent any rights under a contract for
                differences or under any other contract the purpose or pretended
                purpose of which is to secure a profit or avoid a loss;
              </li>
              <li>
                is not intended to be a representation of money (including
                electronic money), security, commodity, bond, debt instrument,
                unit in a collective investment scheme or any other kind of
                financial instrument or investment;
              </li>
              <li>
                is not a loan to the Company, the Distributor or any of their
                respective affiliates, is not intended to represent a debt owed
                by the Company, the Distributor or any of their respective
                affiliates, and there is no expectation of profit; and
              </li>
              <li>
                does not provide the token holder with any ownership or other
                interest in the Company, the Distributor or any of their
                respective affiliates.
              </li>
            </ol>
            <Typography variant="body2" paragraph color="textSecondary">
              Notwithstanding the $FS distribution, users have no economic or
              legal right over or beneficial interest in the assets of the
              Company, the Distributor, or any of their affiliates after the
              token distribution.
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              To the extent a secondary market or exchange for trading $FS does
              develop, it would be run and operated wholly independently of the
              Company, the Distributor, the distribution of $FS and
              FantomStarter. Neither the Company nor the Distributor will create
              such secondary markets nor will either entity act as an exchange
              for $FS.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">Informational purposes only</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              The information set out herein is only conceptual, and describes
              the future development goals for FantomStarter to be developed. In
              particular, the project roadmap in the Litepaper is being shared
              in order to outline some of the plans of the FantomStarter team,
              and is provided solely forINFORMATIONAL PURPOSES and does not
              constitute any binding commitment. Please do not rely on this
              information in deciding whether to participate in the token
              distribution because ultimately, the development, release, and
              timing of any products, features or functionality remains at the
              sole discretion of the Company, the Distributor or their
              respective affiliates, and is subject to change. Further, the
              Litepaper or the Website may be amended or replaced from time to
              time. There are no obligations to update the Litepaper or the
              Website, or to provide recipients with access to any information
              beyond what is provided herein.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">Regulatory approval</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              No regulatory authority has examined or approved, whether formally
              or informally, any of the information set out in the Litepaper or
              the Website. No such action or assurance has been or will be taken
              under the laws, regulatory requirements or rules of any
              jurisdiction. The publication, distribution or dissemination of
              the Litepaper or the Website does not imply that the applicable
              laws, regulatory requirements or rules have been complied with.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">
              Cautionary Note on forward-looking statements
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              All statements contained herein, statements made in press releases
              or in any place accessible by the public and oral statements that
              may be made by the Company, the Distributor and/or the
              FantomStarter team, may constitute forward-looking statements
              (including statements regarding the intent, belief or current
              expectations with respect to market conditions, business strategy
              and plans, financial condition, specific provisions and risk
              management practices). You are cautioned not to place undue
              reliance on these forward-looking statements given that these
              statements involve known and unknown risks, uncertainties and
              other factors that may cause the actual future results to be
              materially different from that described by such forward-looking
              statements, and no independent third party has reviewed the
              reasonableness of any such statements or assumptions. These
              forward-looking statements are applicable only as of the date
              indicated in the Litepaper, and the Company, the Distributor as
              well as the FantomStarter team expressly disclaim any
              responsibility (whether express or implied) to release any
              revisions to these forward-looking statements to reflect events
              after such date.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">
              References to companies and platforms
            </Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              The use of any company and/or platform names or trademarks herein
              (save for those which relate to the Company, the Distributor or
              their respective affiliates) does not imply any affiliation with,
              or endorsement by, any third party. References in the Litepaper or
              the Website to specific companies and platforms are for
              illustrative purposes only.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">English language</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              The Litepaper and the Website may be translated into a language
              other than English for reference purpose only and in the event of
              conflict or ambiguity between the English language version and
              translated versions of the Litepaper or the Website, the English
              language versions shall prevail. You acknowledge that you have
              read and understood the English language version of the Litepaper
              and the Website.
            </Typography>
          </div>
          <div className="subContent">
            <Typography variant="h5">No Distribution</Typography>
            <Typography variant="body2" paragraph color="textSecondary">
              No part of the Litepaper or the Website is to be copied,
              reproduced, distributed or disseminated in any way without the
              prior written consent of the Company or the Distributor. By
              attending any presentation on this Litepaper or by accepting any
              hard or soft copy of the Litepaper, you agree to be bound by the
              foregoing limitations.
            </Typography>
          </div>
        </div>
        <div className="termsFooter">
          <Footer />
        </div>
      </div>
    </Container>
  );
}

export default Terms;
