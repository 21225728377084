import React from "react";
import { Box } from "@mui/material";
import SwapLeft from "../../../../assets/svg/purple_swap.svg";
import SwapRight from "../../../../assets/svg/orange_swap.svg";
import Swap from "../../../../assets/png/swap_content.svg";
import Earn from "../../../../assets/png/seed.svg";
import Invest from "../../../../assets/png/earn.png";
import InvestRight from "../../../../assets/svg/invest.svg";
import EarnLeft from "../../../../assets/svg/earn.svg";
import "./feature.css";

export const SwapDemo = () => {
  return (
    <DemoContainer>
      <img
        className="prefix"
        style={{
          position: "absolute",
          top: 0,
          left: "-20px",
          width: "100%",
          zIndex: 2,
        }}
        src={SwapLeft}
      />
      <Box
        className="demo"
        style={{
          height: "70%",
          width: "100%",
          backgroundImage: `url(${Swap})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          transform: "rotateZ(-3deg) skewX(-3deg)",
          position: "relative",
          zIndex: 5,
        }}
      />
      <img
        className="suffix"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "100%",
          zIndex: 10,
        }}
        src={SwapRight}
      />
    </DemoContainer>
  );
};

export const InvestDemo = () => {
  return (
    <DemoContainer>
      <Box
        className="demo"
        style={{
          height: "70%",
          width: "100%",
          backgroundImage: `url(${Invest})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          transform: "rotateZ(5deg) skewX(5deg)",
          position: "relative",
          zIndex: 5,
        }}
      />
      <img
        className="suffix"
        style={{
          position: "absolute",
          bottom: "-60px",
          right: "-100px",
          width: "100%",
          zIndex: 1,
        }}
        src={InvestRight}
      />
    </DemoContainer>
  );
};

export const EarnDemo = () => {
  return (
    <DemoContainer>
      <img
        className="prefix"
        style={{
          position: "absolute",
          top: "-10px",
          left: "-20px",
          width: "100%",
          zIndex: 2,
          transform: "scale(1.3)",
        }}
        src={EarnLeft}
      />
      <Box
        className="demo"
        style={{
          height: "60%",
          width: "100%",
          backgroundImage: `url(${Earn})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          position: "relative",
          zIndex: 5,
        }}
      />
    </DemoContainer>
  );
};

const DemoContainer = ({ children }) => {
  return (
    <Box
      position="relative"
      width="100%"
      height="500px"
      display="flex"
      alignItems="center"
    >
      {children}
    </Box>
  );
};
